<template>
    <r-e-dialog title="选择房间号" :visible.sync="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel" width="600px">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="房间地址" prop="intermediateUuid">
                <el-select v-model="formPublish.intermediateUuid" multiple placeholder="请选择">
                    <el-option v-for="(item,index) in roomList" :key="index" :label="item.apartmentName"
                               :value="item.uuid"/>
                </el-select>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageWarning} from "@custom/message";
import {issueRoomList, sendPermissionlist} from "@/api/lock-permission";

export default {
    name: "go-getails",
    data() {
        return {
            uuid: null,
            dataList: [],
            dialogVisible: false,
            roomList: [],
            formPublish: {
                intermediateUuid: null
            },
            rules: {
                intermediateUuid: [{required: true, message: '地址不能为空', trigger: 'blur'}],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    created() {},
    methods: {
        async openDialog(row) {
            const {uuid} = row;
            this.uuid = uuid;
            await this.allocateNolist(uuid)
            this.dialogVisible = true;
        },

        handleSubmitPublish() {
            let that = this;
            // 表单提交
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    let {intermediateUuid} = that.formPublish;
                    const whoToApplyUuid = that.uuid;
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading(loadingOptions);
                    let paramData = {whoToApplyUuid, intermediateUuid};
                    sendPermissionlist(paramData).then(res => {
                        MessageSuccess("开放成功");
                        that.handleCancel();
                    }).finally(() => loading.close());
                } else MessageWarning("请填写必填值", 1000);
            });
        },

        handleCancel() {
            this.dialogVisible = false;
            this.formPublish = { intermediateUuid: null }
            // 调用父组件方法 刷新列表
            this.$emit('handleSearch');
        },
        async allocateNolist(uuid) {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading(loadingOptions);
            const res = await issueRoomList({uuid}).finally(() => loading.close());
            if (res) {
                let {list} = res;
                that.roomList = list;
            }
        }
    },

}
</script>

<style lang="scss" scoped>
.el-select {
    width: 100%;
}

.flex {
    display: flex;

    /deep/ .el-form-item {
        flex: 1;
        white-space: nowrap;
    }

    /deep/ .tag-group {
        white-space: normal;

        .el-tag {
            margin: 0 VW(5px) VH(5px) 0;
            cursor: pointer;
        }
    }

    /deep/ .samplePhotos {
        white-space: normal;
    }
}
</style>